import { type LoaderFunctionArgs, type MetaFunction } from '@remix-run/node';
import { matchPath } from '@remix-run/react';
import { lazy, Suspense, useEffect, useState } from 'react';

import { LEGACY_ROUTES } from '../../src/AppRoutesLegacyPaths';
import { GlobalLoading } from '../../src/components/GlobalLoading';
import { makeTitle } from '../../src/utils/common';
import { PageAnalyticsSetup } from '../components/PageAnalyticsSetup';

// Using a lazy route here so that in the event of a 404, remix doesn't
// pre-emptively send the entire bundle during client-side render.
const AppRoutes = lazy(() =>
  import('../../src/AppRoutes.client').then((module) => ({
    default: module.Component,
  }))
);

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const pathname = url.pathname;
  const patterns = Object.values(LEGACY_ROUTES);

  // Gatekeeper: only let known patterns through to 404-early if possible
  for (const pattern of patterns) {
    const match = matchPath(pattern, pathname);
    if (match) {
      return {
        // this is meaningless, just something to return in the loader
        path: match.pathname,
      };
    }
  }

  throw new Response('Not Found', { status: 404 });
};

export default function Component() {
  // NOTE: this a stop gap solution to avoid a race condition with <link> elements in production builds of the app.
  // the root cause appears to be with the built route manifests; because AppRoutes is imported lazily, the generated
  // route manifest does not include the imported css files from nested components, e.g., slick.scss in Row.tsx.
  // when navigating between pages, such as /home and /venue/:id, the <link> elements are sync'd according to the route
  // manifest. in this case, when navigating to /venue/:id, we _remove_ anything that is not explicitly referenced on
  // the manifest.
  //
  // the particular bug here is this:
  //  * both /home and /venue/:id have an implicit dependency on /assets/GamePackCollection-*.css (or some
  //    equivalent bundled css containing slick.scss).
  //  * /home references this asset in the manifest
  //  * /venue/:id does not (on account of the manifest generation bug, caused by the lazy import).
  //  * when visiting /home, /assets/GamePackCollection-*.css is added as a <link>
  //  * then navigating to /venue/:id, this asset is removed, and the styles break.
  //
  // this is complicated by asset preloading with remix. when navigating to /venue/:id, a separate asset preloading script
  // executes. this script correctly references /assets/GamePackCollection-*.css, and it will attempt to append it as a <link>
  // to <head>.
  //
  // we can take advantage of this to ensure the scripts are loaded.
  //
  //  * user navigates to /home and /assets/GamePackCollection-*.css is added as a <link>
  //  * user navigates to /venue/:id and this asset is removed.
  //  * because we are using an effect here, the asset preloading script is not immediately executed. it is only executed
  //    after the initial render and the effect runs.
  //  * when `isReady` is true, the asset preloading script executes and the <link> is added.
  //
  // NOTE: this is empirically _NOT_ how execution works with <ClientOnly>. in testing, we found that asset preloading
  // executes immediately and races with the code sync the <link>s according to the manifest. in essence, with <ClientOnly>
  // asset preloading runs so quickly it observes /assets/GamePackCollection-*.css as a <link> in the DOM, and does nothing.
  // only then is the asset removed from the DOM to sync according to the manifest.
  //
  // we probably should not rely on this behavior, as it's hacky, and relies on react scheduling to work.
  //
  // see: https://github.com/remix-run/remix/issues/9630
  // see: https://luna-park.atlassian.net/browse/LP-3696
  // see: https://stackblitz.com/edit/remix-run-remix-rd4zsmfy?file=app%2Froutes%2F_layout.play2.tsx
  const [isReady, setIsReady] = useState(false);
  useEffect(() => {
    setIsReady(true);
  }, []);

  return (
    <div
      data-debugid='shell-app-routes'
      style={{
        width: '100vw',
        height: '100%',
        position: 'fixed',
        background: 'black',
      }}
    >
      <PageAnalyticsSetup>
        {isReady && (
          <Suspense fallback={<GlobalLoading debug='shell-app-routes' />}>
            <AppRoutes />
          </Suspense>
        )}
      </PageAnalyticsSetup>
    </div>
  );
}

export const meta: MetaFunction = () => {
  const desc =
    'Company Culture Reimagined For Modern Teams, Leverage the power of hosted social games and icebreakers to boost team engagement.';

  return [
    { title: makeTitle('') },
    { 'og:title': 'Luna Park' },
    { 'og:description': desc },
    { description: desc },
  ];
};
